@media ( max-width: 480px ) {
    .nav-item {
        width: 100%;
        cursor: pointer;
    }
}
.aspect-ratio-wrapper {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
 }
 .aspect-ratio-wrapper iframe {
	position:absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
 }