@media (max-width: 420px){
	.top-logo-navbar{
		width:180px;
	}
}
@media (max-width: 370px){
	.top-logo-navbar{
		width:160px;
	}
}
@media (max-width: 300px){
	.top-logo-navbar{
		width:120px;
	}
}
.top-logo-navbar svg {
	width:100%;
	max-width:152px;
}
.navbar.bg-primary.transparent{
	background-color: #2c3e50AA!important;
}
.navbar.bg-primary{
	background-color: #727E8A!important;
}
.navbar-collapse{
	background-color: #727E8A!important;
	text-align: right;
}
@media (min-width: 768px) {
	.transparent .navbar-collapse{
		background-color: #727E8A00!important;
	}
}
.navbar a{
	color: #FFFFFF;
	text-decoration: none!important;
}
.jumbotron {
	background-color: #FFFFFF00!important;
}
body{
	background-image: linear-gradient(#DDDDDD00 100px, #CCCCCCFF 400px, #DDDDDD00 800px);
	background-repeat: no-repeat;
	min-height: 1000px;
}
footer center .nav-link{
	display: inline-block;
}