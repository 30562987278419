.aspect-ratio-wrapper {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
 }
 .aspect-ratio-wrapper iframe {
	position:absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
 }
 .jumbotron + .jumbotron {
	 padding-top:0px;
 }
 .card {
	 border-color: #FFFFFF00;
 }
 @media (min-width: 500px){
	#headerSteps li:nth-child(1){
		margin-left: -20%;
	}
	#headerSteps li:nth-child(2){
		margin-left: -10%;
	}
	#headerSteps li:nth-child(3){
		margin-left: 0%;
	}
 }